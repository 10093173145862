import { createLogic } from "redux-logic";
import createAuth0Client from "@auth0/auth0-spa-js";

import {
  CHECK_ESRI_AUTHENTICATION,
  ESRI_APP_ID,
  ESRI_PORTAL_URL,
  INVALIDATE_AUTHENTICATION,
  CHECK_AUTHZERO_AUTHENTICATION,
  AUTHZERO_USERID_CLAIM,
  SUCCESSFUL_AUTHENTICATION,
  URL_PARAM_STORAGE_KEY,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_TENANT,
  AUTH0_AUDIENCE
} from "../constants";
import { navigate } from "@reach/router";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";

const checkEsriAuthenticationLogic = createLogic({
  type: CHECK_ESRI_AUTHENTICATION,
  async process({ globalActions, selectors, getState }, dispatch, done) {
    try {
      const { getLabel } = selectors;
      const info = new OAuthInfo({
        appId: ESRI_APP_ID,
        popup: false,
        portalUrl: ESRI_PORTAL_URL
      });
      esriId.setOAuthRedirectionHandler((info) => {
        info.authorizeParams.redirect_uri =
          info.authorizeParams.redirect_uri.split("?")[0];
        const params = { ...info.authorizeParams, force_login: true };
        window.location = `${info.authorizeUrl}?${Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&")}`;
      });

      esriId.registerOAuthInfos([info]);
      const { userId, token } = await esriId.getCredential(
        `${info.portalUrl}/sharing`
      );
      const labels = getLabel(getState());
      const { successfulAuthentication, setLoadingText } = globalActions;
      dispatch(setLoadingText(labels["SUCCESSFUL_LOGIN_LABEL"]));
      dispatch(
        successfulAuthentication({
          userId,
          token
        })
      );

      const { loadUser } = globalActions;
      dispatch(loadUser(userId));
      done();
    } catch (e) {
      const { errorAuthentication } = globalActions;
      dispatch(errorAuthentication(e));
      done();
    }
  }
});

const checkAuthZeroAuthenticationLogic = createLogic({
  type: CHECK_AUTHZERO_AUTHENTICATION,
  async process({ globalActions, selectors, getState }, dispatch, done) {
    try {
      const { successfulAuthentication, setLoadingText, setAuth0Client } =
        globalActions;
      const sessionPathKey = "windowPath";
      const isRedirect = () => {
        return (
          window.location.search.includes("code=") &&
          !window.location.search.includes("supportSignUp=")
        );
      };
      if (!isRedirect()) {
        sessionStorage.setItem(sessionPathKey, window.location.pathname);
        sessionStorage.setItem(URL_PARAM_STORAGE_KEY, window.location.search);
      }

      const auth0Client = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirect_uri: window.location.origin
      });
      dispatch(setAuth0Client(auth0Client));

      const storedUrl = sessionStorage.getItem(sessionPathKey);
      if (isRedirect()) {
        const storedUrlParam = sessionStorage.getItem(URL_PARAM_STORAGE_KEY);
        const redirectPath = storedUrl
          ? `${storedUrl}${storedUrlParam ? storedUrlParam : ""}`
          : window.location.pathname;
        await auth0Client.handleRedirectCallback();
        window.history.replaceState({}, document.title, redirectPath);
        navigate(redirectPath);
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      if (!isAuthenticated) auth0Client.loginWithRedirect({});

      const user = await auth0Client.getUser();
      const userId = user[AUTHZERO_USERID_CLAIM];

      const claims = await auth0Client.getIdTokenClaims();
      const token = claims.__raw;

      const { getLabel } = selectors;

      const labels = getLabel(getState());
      dispatch(setLoadingText(labels["SUCCESSFUL_LOGIN_LABEL"]));
      dispatch(
        successfulAuthentication({
          userId,
          token
        })
      );

      const { loadUser } = globalActions;
      dispatch(loadUser(userId));
      sessionStorage.removeItem(sessionPathKey);
      sessionStorage.removeItem(URL_PARAM_STORAGE_KEY);

      done();
    } catch (e) {
      const { errorAuthentication } = globalActions;
      dispatch(errorAuthentication(e));
      done();
    }
  }
});

const invalidateAuthenticationLogic = createLogic({
  type: INVALIDATE_AUTHENTICATION,
  async process({ selectors, getState, globalActions }, dispatch, done) {
    try {
      const { setLoadingText, clearAuthentication } = globalActions;
      const { getLabel, getAuth0Client } = selectors;
      const labels = getLabel(getState());
      const { SIGNING_OUT_LABEL } = labels;
      await dispatch(setLoadingText(SIGNING_OUT_LABEL));
      const logoutOptions = {
        returnTo: `${window.location.origin}/logout`
      };
      const auth0Client = getAuth0Client(getState());
      await auth0Client.logout(logoutOptions);
      dispatch(clearAuthentication());

      done();
    } catch (e) {
      console.log(e);
      done();
    }
  }
});

const successfulAuthenticationLogic = createLogic({
  type: SUCCESSFUL_AUTHENTICATION,
  latest: true,
  process({ globalActions, action }, dispatch, done) {
    const { token } = action.payload;
    const { setupInterceptors } = globalActions;
    dispatch(setupInterceptors(token));

    done();
  }
});

export default [
  checkEsriAuthenticationLogic,
  checkAuthZeroAuthenticationLogic,
  invalidateAuthenticationLogic,
  successfulAuthenticationLogic
];
